const GOOD_COLOR = "#66cc66";
const BAD_COLOR = "#ff6666";
const ORIGINAL_COLOR = "#F8FCFE";

function changeColor(element, color) {
    element.style.backgroundColor = color;
}

function showMessage(element, message, color) {
    element.style.color = color;
    element.innerHTML = message;
}

export function checkPass() {
    let pass1 = document.getElementById("pass1");
    let pass2 = document.getElementById("pass2");
    let message = document.getElementById("error-nwl");

    if (pass1.value.length > 5) {
        changeColor(pass1, GOOD_COLOR);
        showMessage(message, "Número de caracteres ok!", GOOD_COLOR);
    } else {
        changeColor(pass1, BAD_COLOR);
        showMessage(
            message,
            "Tienes que introducir al menos 6 dígitos!",
            BAD_COLOR
        );
        return;
    }

    let pass1Value = pass1.value;
    let pass2Value = pass2.value;

    if (!/[a-z]/i.test(pass1Value) || !/[a-z]/i.test(pass2Value)) {
        changeColor(pass1, BAD_COLOR);
        changeColor(pass2, BAD_COLOR);
        showMessage(
            message,
            "Tu contraseña debe contener al menos una letra.",
            BAD_COLOR
        );
        return;
    } else if (!/\d/.test(pass1Value) || !/\d/.test(pass2Value)) {
        changeColor(pass1, BAD_COLOR);
        changeColor(pass2, BAD_COLOR);
        showMessage(
            message,
            "Tu contraseña debe contener al menos un dígito.",
            BAD_COLOR
        );
        return;
    }

    if (pass1Value === pass2Value) {
        changeColor(pass2, GOOD_COLOR);
        showMessage(message, "¡Bien!", GOOD_COLOR);
    } else {
        changeColor(pass2, BAD_COLOR);
        showMessage(message, "¡La contraseña no coincide!", BAD_COLOR);
    }
}
