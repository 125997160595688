const GOOD_COLOR = "#66cc66";
const BAD_COLOR = "#ff6666";
const ORIGINAL_COLOR = "#F8FCFE";

function changeColor(element, color) {
    element.style.backgroundColor = color;
}

function showMessage(element, message, color) {
    element.style.color = color;
    element.innerHTML = message;
}

export function checkPass() {
    let pass1 = document.getElementById("password");
    let message = document.getElementById("error-nwl");

    if (pass1.value.length > 5) {
        changeColor(pass1, GOOD_COLOR);
        showMessage(message, "Número de caracteres ok!", GOOD_COLOR);
    } else {
        changeColor(pass1, BAD_COLOR);
        showMessage(
            message,
            "Tienes que introducir al menos 6 dígitos!",
            BAD_COLOR
        );
        return;
    }

    let pass1Value = pass1.value;

    if (!/[a-z]/i.test(pass1Value)) {
        changeColor(pass1, BAD_COLOR);
        changeColor(pass2, BAD_COLOR);
        showMessage(
            message,
            "Tu contraseña debe contener al menos una letra.",
            BAD_COLOR
        );
        return;
    } else if (!/\d/.test(pass1Value)) {
        changeColor(pass1, BAD_COLOR);
        showMessage(
            message,
            "Tu contraseña debe contener al menos un dígito.",
            BAD_COLOR
        );
        return;
    }
}

export function CopyToClipboard(value) {
    const toastWarning = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    let textToClipboard = value;

    try {
        navigator.clipboard.writeText(textToClipboard);
        toastWarning.fire({
            icon: "success",
            title: "Se copió el Token al portapapeles!",
        });
    } catch (e) {
        toastWarning.fire({
            icon: "error",
            title: "Tu navegador no admite copiar al portapapeles!",
        });
    }
}
