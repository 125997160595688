export function CheckBeforeLogin(event, url) {
    event.preventDefault();

    const btnLogin = document.getElementById("btn-login");
    const loader = document.getElementById("loader-login");
    btnLogin.disabled = true;
    loader.style.display = "inline-block";

    const toastWarning = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const emailInput = document.getElementById("email");
    const passwordInput = document.getElementById("password");

    if (emailInput.value.trim() === "" || passwordInput.value.trim() === "") {
        btnLogin.disabled = false;
        loader.style.display = "none";

        return toastWarning.fire({
            icon: "warning",
            title: "Los casilleros de email y contraseña no pueden estar vacíos!",
        });
    }

    if (
        $("#email")
            .val()
            .trim()
            .match(
                /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
            ) == null
    ) {
        btnLogin.disabled = false;
        loader.style.display = "none";

        return toastWarning.fire({
            icon: "warning",
            title: "El formato de email que ingresó es incorrecto!",
        });
    }

    return goLogin(url);
}

async function goLogin(url) {
    const btnLogin = document.getElementById("btn-login");
    const loader = document.getElementById("loader-login");
    btnLogin.disabled = true;
    loader.style.display = "inline-block";

    const toastWarning = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    // Objeto con los datos del formulario
    const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: $("#loginForm").serialize(),
        url,
    };

    await axios(options)
        .then(function (response) {
            if (response.data.login) {
                if (!isPWA()) {
                    window.location.href = response.data.panel;
                } else {
                    window.location.href = response.data.cierrecaja;
                }
            }
        })
        .catch(function (error) {
            btnLogin.disabled = false;
            loader.style.display = "none";

            toastWarning.fire({
                icon: "error",
                title: error.response.data.message,
            });
        });

    function isPWA() {
        return (
            window.matchMedia("(display-mode: standalone)").matches ||
            window.navigator.standalone ||
            document.referrer.startsWith("android-app://")
        );
    }
}

export function loadStorageDataLogin(rmCheck, emailInput, passwordInput) {
    if (localStorage.checkbox === "checked") {
        // Cambiado a "checked"
        rmCheck.checked = true;
        emailInput.value = localStorage.email;
        passwordInput.value = localStorage.password;
    } else {
        rmCheck.checked = false;
        emailInput.value = "";
        passwordInput.value = "";
    }
}

export function lsRememberMeLogin(rmCheck, emailInput, passwordInput) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const storageKey = "email";

    if (
        rmCheck.checked &&
        emailInput.value.trim() !== "" &&
        passwordInput.value.trim() !== "" &&
        emailRegex.test(emailInput.value)
    ) {
        localStorage.setItem(storageKey, emailInput.value);
        localStorage.setItem("password", passwordInput.value);
        localStorage.setItem("checkbox", "checked"); // Cambiado a "checked"
    } else {
        localStorage.removeItem(storageKey);
        localStorage.removeItem("password");
        localStorage.removeItem("checkbox");
    }
}

export function validateRememberPassword(passwordInput) {
    const password = passwordInput.value.trim();
    const storageKey = "password";

    localStorage.removeItem(storageKey);

    if (password !== "") {
        localStorage.setItem(storageKey, passwordInput.value);
    }
}

export function validateRememberEmail(emailInput) {
    const email = emailInput.value.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const storageKey = "email";

    localStorage.removeItem(storageKey);

    if (email !== "" && emailRegex.test(email)) {
        localStorage.setItem(storageKey, email);
    }
}
