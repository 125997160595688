import _ from "lodash";
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

// Jquery $
import $ from "jquery/dist/jquery";
window.$ = window.jQuery = $;

// Bootstrap
import "@popperjs/core";
import "bootstrap/dist/js/bootstrap";

// Fortawesome
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/v4-shims";

// SELECT2
import "select2/dist/js/select2";

// Chart
import Chart from "chart.js/auto";
window.Chart = Chart;

// DATATABLE
import "laravel-datatables-vite";

// jsPDF
import jsPDF from "jspdf";
window.jsPDF = jsPDF;

import jsPDFAutotable from "jspdf-autotable";
window.jsPDFAutotable = jsPDFAutotable;

// DATATABLE PDF EXPORT
import pdfmake from "pdfmake";
window.pdfmake = pdfmake;

// SweetAlerts
import Swal from "sweetalert2";
window.Swal = Swal;

// Moment
import Moment from "moment";
window.Moment = Moment;

// Main dashboard scripts
window.Main = require("./class/main.js");
window.Register = require("./login/register.js");
window.RecoverPassword = require("./login/recoverPassowrd.js");
window.Login = require("./login/login.js");
